import css from './CountrySelector.module.css';
import { Autocomplete, InputAdornment, Box, TextField} from '@mui/material';
import { useCountries } from 'use-react-countries';
import { getName, getCode } from 'country-list';

function CountrySelector(props) {
    const { countries } = useCountries();

    return (
        <div className={css.inputContainer}>
            <p className={css.inputLabel}>{`${props.label}${props.required ? "*" : ""}`}</p>
            <Autocomplete 
                id="country"
                name="country"
                options={countries}
                getOptionLabel={(option) => option.name}
                value={props.value ? countries.filter(country => country.name === getName(props.value))[0] : countries.filter(country => country.name === getName("PL"))[0]}
                onChange={(e, v) => {
                    if (v !== null) {
                        props.onChange(getCode(v.name))
                    }
                }}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.emoji} {option.name}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputProps={{
                        ...params.inputProps,
                        }}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: <InputAdornment position='end' className={css.countryFlag}>{countries.filter(country => country.name === params.inputProps.value)[0]?.emoji || ""}</InputAdornment>
                        }}
                        className={css.input}
                    />
                )}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                disableClearable
            />
        </div>
    );
}

export default CountrySelector;
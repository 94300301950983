import css from './TextField.module.css';
import { TextField as MuiTextField } from '@mui/material';

function TextField(props) {
    
    return (
        <div className={css.inputContainer}>
            <p className={css.inputLabel}>{`${props.label}${props.required ? "*" : ""}`}</p>
            <MuiTextField 
                className={css.input}
                variant="outlined" 
                // label={props.label} 
                onChange={(e) => props.onChange(e.target.value)}
                value={props.value || ""}
                placeholder={props.placeholder}
                helperText={props.helperText}
                error={props.error}
            /> 
        </div>
    );
}

export default TextField;
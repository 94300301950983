import css from './Header.module.css';
import logo from './res/logo.svg'

function Header(props) {
    return (
        <>
            <img src={logo} className={css.logo} alt='enforcemed-logo' />

            <div className={css.container}>
                <div className={css.titleContainer}>
                    <p className={css.title}>{props.title}</p>
                </div>
            </div>
        </>
    );
}

export default Header;
import { createContext, useContext, useEffect, useState } from "react";
import { onAuthStateChanged, signOut, RecaptchaVerifier, signInWithPhoneNumber, getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { getFirestore } from "firebase/firestore";

const firebaseContext = createContext();

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// enable app check in production
if (process.env.NODE_ENV === "production" && window.location.hostname === process.env.REACT_APP_HOSTNAME) {
    initializeAppCheck(app, {
        provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_GCP_RECAPTCHA_ENTERPRISE_KEY),
        isTokenAutoRefreshEnabled: true
    });
}

const auth = getAuth(app);
const db = getFirestore(app);

export function FirebaseContextProvider({ children }) {
    const [user, setUser] = useState({});

    function logOut() {
        return signOut(auth);
    }

    async function setUpRecaptcha(phoneNumber, container) {
        const recaptchaVerifier = new RecaptchaVerifier(
            container,
            {
                size: "invisible"
            },
            auth
        );

        await recaptchaVerifier.render();
        return signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
            if (process.env.NODE_ENV === "development") { console.log(currentuser); }
            setUser(currentuser);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <firebaseContext.Provider 
            value={{
                // context objects and functions
                user,
                logOut,
                setUpRecaptcha,

                // firebase products
                app,
                analytics,
                auth,
                db
            }}
        >
            {children}
        </firebaseContext.Provider>
    );
}

export function useFirebase() {
    return useContext(firebaseContext);
}
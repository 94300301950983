import css from './Start.module.css';
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';
import { Button } from '@mui/material';
import languages from '../res/languages.json';
import { useLanguage } from '../../../../context/Language';
import { useEffect } from 'react';

function Start (props) {
    const { language, setLanguage } = useLanguage();

    useEffect(() => {
        setLanguage(props.routeLanguage);
    }, [props.routeLanguage, setLanguage]);

    return (
        <div className={css.container} style={{display: props.visible ? "flex" : "none"}}>
            <p className={css.text}>
                <span className={css.textBold}>{languages.components.startTextBold[language]}</span>
                <br></br>
                {languages.components.startTextPT1[language]}
                <br></br>
                {languages.components.startTextPT2[language]}
            </p>

            <LanguageSelector codes={["pl", "ua", "gb"]} default={props.routeLanguage} onSelected={setLanguage}/>

            <Button variant='contained' onClick={props.onStart} className={css.button} type='button'>
                {languages.components.start[language]}
            </Button>

            <p className={css.disclosure}>
                {`${languages.components.clause_pt1[language]}, `}
                <a href="mailto:biuro@enforcemed.pl">biuro@enforcemed.pl</a>
                {`. ${languages.components.clause_pt2[language]} `}
                <a href="https://enforcemed.pl/wp-content/uploads/2023/07/ochrona_danych_osobowych_pacjentow.pdf" target="_blank" rel="noreferrer">{`${languages.components.here[language]}`}</a>
                .
            </p>
        </div>
    )
}

export default Start;
import { useState } from 'react';
import css from './LanguageSelector.module.css';

function LanguageSelector (props) {
    const [selected, setSelected] = useState(props.default);

    return (
        <div className={css.container}>
            {props.codes.map(code => {
                return (
                    <div 
                        className={css.languageButton} 
                        style={{opacity: selected !== code ? "0.35" : "1"}}
                        onClick={() => {setSelected(code); props.onSelected(code)}}
                        key={`language-select-${code}`}
                    >
                        <p className={css.languageText}>{code !== "gb" ? code : "en"}</p>
                    </div>
                )
            })}
        </div>
    )
}

export default LanguageSelector;
import css from './PatientSurvey.module.scss';
import Header from './components/Header/Header';
import Cookies from './components/Cookies/Cookies';

import Start from './screens/Start/Start';
import PhoneVerification from './screens/PhoneVerification/PhoneVerification';
import SurveySent from './screens/SurveySent/SurveySent';
import PatientForm from './screens/PatientForm/PatientForm';

import { useState, useEffect } from 'react';
import { ThemeProvider, createTheme, StyledEngineProvider} from '@mui/material';
import { doc, setDoc, getDoc, serverTimestamp } from "firebase/firestore";
import { useFirebase } from '../../context/FirebaseContext';
import { useLanguage } from '../../context/Language';
import languages from './screens/res/languages.json';

// alter default materialUI theme
const theme = createTheme({
    palette: {
        primary: {
          main: '#4090C1',
        },
        secondary: {
          main: '#E43E44',
        },
        error: {
          main: '#E43E44',
        },
      },
      typography: {
        fontFamily: 'Objectivity'
      }
});

function PatientSurvey(props) {
  const { language } = useLanguage();
  const { user, db, logOut } = useFirebase();
  const [loaded, setLoaded] = useState(false);
  const [stage, setStage] = useState(1);
  const [title, setTitle] = useState("FORMULARZ PACJENTA");
  const [phone, setPhone] = useState("");

  // on submit form callback (add form to firebase firestore)
  const onFormSubmit = async ({ formData }) => {
    if (process.env.NODE_ENV === "development") {console.log({...formData, form_version: 2});}

    try {
      const docRef = doc(db, "patient-form", user.uid);
      await setDoc(docRef, {
        ...formData, 
        form_version: 2,
        form_timestamp: serverTimestamp()
      });

      setStage(stage + 1);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const checkForPreviouslySentForm = async () => {
      const docRef = doc(db, "patient-form", user.uid);
      const docData = await getDoc(docRef);
      
      if (docData.exists()) {
        setStage(4);
        return;
      }

      setPhone(user?.phoneNumber || "");
      setStage(stage + 1);
    }

    if (user !== null && !loaded && stage === 1) {
      logOut();
    }

    if (user !== null && loaded && stage === 2) {
      checkForPreviouslySentForm();
    }

    setLoaded(true);
  }, [user, loaded, logOut, stage, db]);

  useEffect(() => {
    if (stage === 1) {
      setTitle(languages.components.title[language]);
    }

    if (stage === 4) {
      setTitle(languages.components.end_title[language]);
    }
  }, [language, stage]);

  return (
      <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
              <div className={css.container}>
                  <Header title={title} />

                  <div className={`${css.contentContainer} ${stage !== 3 ? css.contentContainerSmall : ""}`}>
                      
                    <Start visible={stage === 1} onStart={() => setStage(stage + 1)} routeLanguage={props.routeLanguage} />
                    <PhoneVerification visible={stage === 2} />
                    <PatientForm visible={stage === 3} phone={phone} onSubmit={onFormSubmit} setTitle={setTitle} />
                    <SurveySent visible={stage === 4} />

                  </div>

                  <Cookies />
              </div>
          </StyledEngineProvider>
      </ThemeProvider>
  );
}

export default PatientSurvey;
import css from './DateField.module.css';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import 'dayjs/locale/pl';
import dayjs from 'dayjs';
import './DatePicker.css';

function DateField(props) {

    return (
        <div className={css.inputContainer}>
            <p className={css.inputLabel}>{`${props.label}${props.required ? "*" : ""}`}</p>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
                <DatePicker
                    className={css.input}
                    disableFuture
                    openTo="year"
                    views={['year', 'month', 'day']}
                    value={props.value}
                    onChange={(newValue) => props.onChange(dayjs(newValue).format("YYYY-MM-DD"))}
                    renderInput={(params) => <TextField {...params}/>}
                    InputAdornmentProps={{ position: "end" }}
                />
            </LocalizationProvider>
        </div>
    );
}

export default DateField;
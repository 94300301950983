import css from './PatientForm.module.scss';
import { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { Button } from '@mui/material';
import languages from '../res/languages.json';
import { useLanguage } from '../../../../context/Language';
import formSchema from './res/formSchema.json';
import uiFormSchema from './res/uiFormSchema.json';
import _ from 'lodash';
import { customizeValidator } from '@rjsf/validator-ajv8';
import localizer from 'ajv-i18n';
import Form from '@rjsf/mui';
import { LoadingButton } from '@mui/lab';
import TextField from '../../components/TextField/TextField';
import DateField from '../../components/DateField/DateField';
import Radio from '../../components/Radio/Radio';
import { CaretRight, CaretLeft, PaperPlaneTilt } from 'phosphor-react';
import CountrySelector from '../../components/CountrySelector/CountrySelector';

// set localizations for error messages
const localize = {
    gb: localizer.en,
    ua: localizer.en,
    pl: localizer.pl
}

// custom data formats
const customFormats = {
    'phone': /^[+][0-9]{3}?[0-9]{3}?[0-9]{4,6}$/
}

const formWidgets = {
    TextWidget: TextField,
    DateWidget: DateField,
    RadioWidget: Radio,
    customCountryWidget: CountrySelector
};

function PatientForm(props) {
    const { language } = useLanguage();
    const formRef = useRef(null);

    const partsTotal = 3;
    const [part, setPart] = useState(1);
    const [revealedParts, setRevealedParts] = useState(1);
    const [schema, setSchema] = useState(null);
    const [formData, setFormData] = useState({});

    const [sending, setSending] = useState(false);

    // create a schema for the form
    // merge localized (in correct language) titles of inputs from languages json as definitions with form schema json
    useLayoutEffect(() => {
        let formLocaleDefinitions = _.cloneDeep(languages.form);
        
        _.forEach(formLocaleDefinitions, (categoryValue, categoryKey) => {
            _.forEach(categoryValue, (inputValue, inputKey) => {
                if (_.isObject(inputValue)) {
                    _.set(
                        formLocaleDefinitions, 
                        `${categoryKey}.${inputKey}`, 
                        _.isObject(inputValue[language]) ? inputValue[language] : 
                        inputKey === "title" ? inputValue[language] : {title: inputValue[language]}
                    );
                }
            });
        });
        
        let localeFormSchema = _.merge(formSchema, {definitions: formLocaleDefinitions});
        setSchema(localeFormSchema);
    }, [language]);

    useEffect(() => {
        if (part === 3) {
            setFormData(currentData => {return {...currentData, contact: {phone: props.phone}}});
        }
    }, [props.phone, part]);

    // validate if form is filled, if not show an alert
    const validate = () => {
        if (formRef.current) {
            if (formRef.current.validateForm()) {
                return true;
            } else {
                alert(languages.components.fillMissingData[language]);
                return false;
            }
        }
        return true;
    }

    // custom validation function (extends built in validator) - validates if user checked the agreement
    const customValidator = (formData, errors, uiSchema) => {
        if (formRef.current) {
            const key = Object.keys(formRef.current.props.uiSchema).find(prop => formRef.current.props.uiSchema[prop]["ui:classNames"] === `part-${part}`);
            
            if (key === "relative") {
                if (formData.relative !== undefined) {
                    if (formData.relative.name !== undefined) {
                        if (formData.relative.agreement === false) {
                            errors.relative.agreement.addError("Agreement is required");
                        }
                    }
                }
            }
        }

       return errors;
    }

    const submit = () => {
        const data = _.cloneDeep(formData);
        
        if (data.relative === undefined) {
            data.relative = { agreement: false };
        }

        if (data.relative.agreement === false) {
            data.relative = {
                name: "-",
                surname: "-",
                email: "-",
                phone: "-",
                agreement: false
            };
        }

        props.onSubmit({ formData });
    }

    const showNext = () => {
        if (part < revealedParts) {
            setPart(part + 1);
            return;
        }
        
        if (!validate()) {
            return;
        }

        if (part === partsTotal) {
            if (formRef.current) {
                setSending(true);
                submit();
                // formRef.current.submit();
            }
            return;
        }

        setPart(part + 1);
        setRevealedParts(revealedParts + 1);
    }

    const showPrevious = () => {
        setPart(part - 1);
    }

    useEffect(() => {
        if (formRef.current) {
            const key = Object.keys(formRef.current.props.uiSchema).find(prop => formRef.current.props.uiSchema[prop]["ui:classNames"] === `part-${part}`);
            props.setTitle(languages.form[key].title[language]);
            
            if (key !== "relative") {
                let newSchema = _.cloneDeep(formRef.current.props.schema);
                _.set(newSchema, "required", [`${key}`]);
                setSchema(newSchema);
            }
        }
    }, [part, language, props]);

    // replacement component for errors in json schema form
    // (used to hide default error window - no render method)
    const ErrorListTemplate = (props) => {}

    return (
        <div className={css.container} style={{display: props.visible ? "flex" : "none"}}>
            <div className={css.formContainer} showpart={`part-${part}`}>
                {schema !== null && props.visible ?
                    <Form
                        schema={schema}
                        uiSchema={uiFormSchema}
                        validator={customizeValidator({customFormats}, localize[language])}
                        onChange={(e) => setFormData(e.formData)}
                        onSubmit={props.onSubmit}
                        className={css.form}
                        onError={() => {}}
                        ref={formRef}
                        formData={formData}
                        templates={{ ErrorListTemplate }}
                        customValidate={customValidator}
                        widgets={formWidgets}
                    />
                    : null
                }
            </div>

            <div className={css.buttonContainer}>
                <Button 
                    variant='contained' 
                    onClick={() => showPrevious()} 
                    style={{
                        pointerEvents: part - 1 < 1 ? "none" : "auto",
                        opacity: part - 1 < 1 ? 0 : 0.35
                    }}
                    className={css.buttonBack}
                >
                    <span className={css.buttonText}>{languages.components.back[language]}</span>
                    <CaretLeft className={css.buttonArrows} />
                </Button>

                <p className={css.pageNumber}>{`${part}/${partsTotal}`}</p>

                <LoadingButton variant='contained' onClick={() => showNext()} className={css.button} type='button' loading={sending}>
                    <span className={css.buttonText}>{part === partsTotal ? languages.components.send[language] : languages.components.next[language]}</span>
                    {part === partsTotal ? <PaperPlaneTilt className={css.buttonArrows} /> : <CaretRight className={css.buttonArrows} />}
                </LoadingButton>
            </div>
        </div>
    )
}

export default PatientForm;
import { createContext, useContext, useState } from "react";

const LanguageContext = createContext();

export function LanguageContextProvider({ children }) {
    const [language, setLanguage] = useState("pl");

    return (
        <LanguageContext.Provider value={{ language, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    )
}

export function useLanguage() {
    return useContext(LanguageContext);
}
import css from './SurveySent.module.css';
import { CheckCircle } from 'phosphor-react';
import languages from '../res/languages.json';
import { useLanguage } from '../../../../context/Language';

function SurveySent(props) {
    const { language } = useLanguage();

    return (
        <div className={css.container} style={{display: props.visible ? "flex" : "none"}}>
            <p className={css.text}>
                <span className={css.textBold}>{languages.components.thankyouPT1[language]}</span>
                <br></br>
                <span>{languages.components.thankyouPT2[language]}</span>
            </p>
            <CheckCircle className={css.check} />
        </div>
    );
}

export default SurveySent;
import CookieConsent from "react-cookie-consent";
import { useLanguage } from "../../../../context/Language";
import languages from '../../screens/res/languages.json';
import RecaptchaDisclosure from "../RecaptchaDisclosure/RecaptchaDisclosure";
import "./Cookies.css";

function Cookies() {
    const { language } = useLanguage();

    return (
        <CookieConsent
            location="bottom"
            buttonText={languages.disclosures.cookies.understand[language]}
            debug={process.env.NODE_ENV === "production" ? false : true}
            style={{background: 'var(--foreground)'}}
            contentStyle={{margin: 0, paddingTop: "15px", paddingBottom: "15px", paddingLeft: "20px", paddingRight: "20px"}}
            buttonStyle={{ display: "none" }}
        >
            <RecaptchaDisclosure />
        </CookieConsent>
    );
}

export default Cookies;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

// import language context
import { LanguageContextProvider } from './context/Language';

// import firebase context
import { FirebaseContextProvider } from './context/FirebaseContext';

// import routes
import PatientSurvey from './pages/PatientSurvey/PatientSurvey';

// react-router
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <PatientSurvey routeLanguage="pl" />
  },
  {
    path: "/en",
    element: <PatientSurvey routeLanguage="gb" />
  },
  {
    path: "/pl",
    element: <PatientSurvey routeLanguage="pl" />
  },
  {
    path: "/ua",
    element: <PatientSurvey routeLanguage="ua" />
  }
]);

root.render(
  <React.StrictMode>
    <FirebaseContextProvider>
      <LanguageContextProvider>
        <RouterProvider router={router} />
      </LanguageContextProvider>
    </FirebaseContextProvider>
  </React.StrictMode>
);
import css from './RecaptchaDisclosure.module.css';
import { useLanguage } from '../../../../context/Language';
import languages from '../../screens/res/languages.json';

function RecaptchaDisclosure() {
    const { language } = useLanguage();

    return (
        <div className={css.container}>
            <p className={css.recaptchaText}>
                {languages.disclosures.recaptcha.pt1[language]}&nbsp;
                <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">{languages.disclosures.recaptcha.privacy[language]}</a> {languages.disclosures.recaptcha.and[language]}&nbsp;
                <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">{languages.disclosures.recaptcha.terms[language]}</a> {languages.disclosures.recaptcha.apply[language]}.
            </p>
        </div>
    );
}

export default RecaptchaDisclosure;
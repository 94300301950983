import css from './PhoneVerification.module.css';
import { useState } from 'react';
import languages from '../res/languages.json';
import { useLanguage } from '../../../../context/Language';
import { useFirebase } from '../../../../context/FirebaseContext';
import { LoadingButton } from '@mui/lab';
import TextField from '../../components/TextField/TextField';

function PhoneVerification(props) {
    const { language } = useLanguage();
    const { setUpRecaptcha } = useFirebase();
    const [isSMSSent, setSMSSent] = useState(false);
    const [smsCode, setSMSCode] = useState("");
    const [phone, setPhone] = useState("");
    const [recaptchaResult, setRecaptchaResult] = useState({});

    const [isCorrectPhone, setIsCorrectPhone] = useState(true);
    const [isCorrectCode, setIsCorrectCode] = useState(true);
    const [isSmsSendError, setIsSmsSendError] = useState(false);
    const [isSmsLoading, setSmsLoading] = useState(false);
    const [isVerifyLoading, setVerifyLoading] = useState(false);

    const checkForCorrectPhoneNumberFormat = () => {
        const phoneFormatRegex = /^[+][0-9]{3}?[0-9]{3}?[0-9]{4,6}$/;
        
        if (!phoneFormatRegex.test(phone)) {
            setIsCorrectPhone(false);
            return false;
        }

        setIsCorrectPhone(true);
        return true;
    }

    const smsSend = async () => {
        if (!checkForCorrectPhoneNumberFormat()) {
            return;
        }
        setIsSmsSendError(false);
        setSmsLoading(true);

        try {
            const response = await setUpRecaptcha(phone, "recaptcha-container");
            setRecaptchaResult(response);
            setSmsLoading(false);
            setSMSSent(true);
        } catch (error) {
            console.log(error);
            setSmsLoading(false);
            setIsSmsSendError(true);
        }
    }

    const smsVerify = async () => {
        if (smsCode === "") {
            setIsCorrectCode(false);
            return;
        }

        setIsCorrectCode(true);
        setVerifyLoading(true);

        try {
            await recaptchaResult.confirm(smsCode);
            setVerifyLoading(false);
        } catch (error) {
            setVerifyLoading(false);
            setIsCorrectCode(false);
            console.log(error);
        }
    }

    return (
        <>
        <div className={css.container} style={{display: props.visible ? "flex" : "none"}}>
            <p className={css.text}>{languages.components.smsVerification[language]}</p>

            {isSMSSent ? 
                <TextField 
                    variant="outlined" 
                    label={languages.components.smsCode[language]} 
                    onChange={(value) => setSMSCode(value)}
                    value={smsCode}
                    helperText={!isCorrectCode ? languages.components.incorrectCode[language] : null}
                    error={!isCorrectCode}
                /> 
            : null}

            {!isSMSSent ? 
                <TextField
                    variant="outlined" 
                    label={languages.components.phoneNumber[language]} 
                    onChange={(value) => setPhone(value)}
                    value={phone}
                    placeholder='+XXxxxxxxxxx'
                    helperText={
                        !isCorrectPhone ? 
                            (isSmsSendError ? 
                                languages.components.smsSendError[language] 
                                : 
                                languages.components.incorrectPhone[language]
                            ) 
                            : 
                            null
                    }
                    error={!isCorrectPhone || isSmsSendError}
                /> 
            : null}

            <div className={css.smsButtonsContainer}>
                {!isSMSSent ? 
                    <LoadingButton 
                        variant={isSMSSent ? 'outlined' : 'contained'} 
                        onClick={() => smsSend()} 
                        className={css.button} 
                        type='button' 
                        id="send-sms-button"
                        loading={isSmsLoading}
                    >
                        {languages.components.sendSMS[language]}
                    </LoadingButton>
                    : null
                }

                {isSMSSent ?
                    <LoadingButton 
                        variant='contained' 
                        onClick={() => smsVerify()} 
                        className={css.button} 
                        type='button'
                        loading={isVerifyLoading}
                    >
                        {languages.components.confirm[language]}
                    </LoadingButton>
                    : null
                }
            </div>
        </div>
        <div className={css.recaptchaContainer} id="recaptcha-container"/>
        </>
    )
}

export default PhoneVerification;
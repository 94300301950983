import css from './Radio.module.css';
import { ToggleButton } from '@mui/material';
import { Check } from '@mui/icons-material';

function Radio(props) {
    
    return (
        <div className={css.inputContainer}>
            <p className={css.inputLabel}>{`${props.label}${props.required ? "*" : ""}`}</p>
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: "wrap", rowGap: "15px"}}>
                {props?.schema?.oneOf?.map((entry, i) =>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} key={`radio-${i}`}>
                        <ToggleButton
                            value={entry.const}
                            selected={props.value === entry.const ? true : false}
                            className={css.radioButton}
                            onChange={() => props.onChange(entry.const)}
                            style={{ backgroundColor: props.value === entry.const ?
                                "var(--main)"
                                :
                                "var(--inputBackground)"
                             }}
                        >
                            {props.value === entry.const && <Check className={css.radioCheck} /> }
                        </ToggleButton>

                        <p className={css.radioLabel}>{entry.title}</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Radio;